/* eslint-disable */
import {API, graphqlOperation} from "aws-amplify";
import {getCollection, getOrders, getProduct, listCollections, listOrders, listProducts} from "@/graphql/queries";
import {deleteOrders} from "@/graphql/mutations";

export async function getCollections()
{
    let collection_list = []
    const results = await API.graphql(graphqlOperation(listCollections))
    const collections = results.data.listCollections.items
    collections.forEach(collection=>{
        collection_list.push(collection)
    })
    return collection_list

}

export async function getCollectionPage(collection_id)
{
    let result = await API.graphql(graphqlOperation(getCollection,{id: collection_id}))
    result = result.data.getCollection
    return result
}

export async function getProductPage(product_id)
{
    console.log("Getting Product: " + product_id)
    let result = await API.graphql(graphqlOperation(getProduct, {id: product_id}))
    return result.data.getProduct
}

export async function getAllProducts()
{
    let results = await API.graphql(graphqlOperation(listProducts))
    results = results.data.listProducts.items
    return results

}

export async function getAllOrders()
{
    let results = await API.graphql(graphqlOperation(listOrders))
    results = results.data.listOrders.items
    return results
}
export async function deleteOrder(order)
{
    let result = await API.graphql(graphqlOperation(deleteOrders, {input: {id: order.id}}))
    return result
}
export async function getOrder(order)
{
    let result = await API.graphql(graphqlOperation(getOrders, {id: order }))
    return result.data.getOrders
}
<template>
  <div>
    <div class="collection-contain">
      <div class="collection-photo-container">
        <img class="collection-photo" :src="preview_image">
      </div>
      <div class="collection-info-container">
        <p class="general-title" >
          {{collection.title}}
        </p>
        <div class="description-container">
          <div class="text-container">
            <p class="general-info">
              {{collection.description}}
            </p>
          </div>
        </div>
        <div class="button-container">
          <router-link class="display-button" :to="{name: 'shop_collection_detail', params:{slug: collection.id}}" style="position: relative; bottom: 0; padding: 10px; ">
            View Collection
          </router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {Storage} from "aws-amplify";

export default {
  name: "TheCollection",
  props: {
    collection: {type: Object, required: true}
  },
  data()
  {
    return{
      preview_image: '',
    }
  },
  mounted() {
    this.getPreviewImages()
  },
  methods:{
    async getPreviewImages()
    {
      console.log(this.collection.image_key)
      if(this.collection.image !== null)
      {
        this.preview_image = await Storage.get(this.collection.image_key)
      }
      else
      {
        this.preview_image = "@/assets/photos/frontpage/imageTwo.jpg"
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Source+Serif+Pro:wght@300&display=swap');

.general-title{
  font-family: 'Orbitron', sans-serif;
  font-size: 70px;
  letter-spacing: 10px;
  font-weight: 500;
  color: black;
}
.general-info{
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
}

.collection-contain{
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 436px;
  background-color: #ffb863;
}

.collection-photo-container{
  grid-column: 1;
  display: flex;
}

.collection-photo{
  object-fit: cover;
  flex: 1 ;
  aspect-ratio: 1/1;
}
.collection-info-container{
  grid-column: 2;
  display: flex;
  min-width: 270px;
  max-width: 450px;
  flex-direction: column;
  align-items: center;


  padding-left: 10px;
}
.description-container{
  height: 350px;
  display: flex;


}
.text-container{
  padding-top: 100px;
  padding-left: 20px;
  justify-self: center;

}
.button-container{
  display: flex;


}

.display-button{

  flex-grow: 1;
  text-align: center;
  font-family: 'Arimo', sans-serif;

  font-size: 20px;
  font-weight: 300;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
  background-color: #831200;
  color: white;

  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 2px solid black;
  text-decoration: none;
  transition: all 0.5s ease-out;

}
.display-button:hover,
.display-button:focus{
  background-color: white;
  color: #831200;
}

@media screen and (max-width: 700px){
  .general-title{
    font-family: 'Orbitron', sans-serif;
    font-size: 60px;
    letter-spacing: 10px;
    font-weight: 500;
    color: black;
  }

}

</style>
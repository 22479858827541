<template>
  <div class="columns" @dragover.prevent @drop.prevent>
    <div class="column is-6 mx-6">
      <p class="title">
        Add / Update Collections
      </p>
      <button v-if="collection.title !== ''" class="button is-black is-fullwidth" @click="clearForm">
        Make New Collection
      </button>
      <p class="subtitle my-1">Id: {{ collection.title.trimStart().trimEnd().replaceAll(' ','-').toLowerCase()}}</p>
      <p class="subtitle my-1">Title: {{collection.title.trimStart().trimEnd()}}</p>
      <input class="input is my-2" v-model="collection.title">
      <p class="subtitle my-1">Description: {{collection.description}}</p>
      <textarea class="textarea is my-2" v-model="collection.description"/>
      <p class="subtitle my-1">Blurb: {{collection.blurb}}</p>
      <input class="input is my-2" v-model="collection.blurb">
      <div class="drop-zone">
        <div class="main-drop" @drop="onMaindrop">
          <p class="subtitle has-text-white">
            Drag Collection Photo Here:
          </p>
          <img v-if="main_photo_pre !==''" class="image my-main-photo" :src="main_photo_pre" alt="whoops">
        </div>
      </div>
      <button class="button is-info is-fullwidth" @click="addNewCollection">
        Add/Update Collection
      </button>
      <button v-if="current_collections.has(collection.id)" class="button is-danger my-2 is-fullwidth" @click="removeCollection">
        Delete Collection
      </button>
    </div>

  </div>
</template>

<script>
import {addCollection, calculateSize, removeCollection} from "@/methods/formmethods";
import {API, graphqlOperation, Storage} from "aws-amplify";
import {updateCollection} from "@/graphql/mutations";
const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;
const MIME_TYPE = "image/jpeg";
const QUALITY = .90;
export default {
  name: "TheSelectionCollection",
  props:{
    new_collection: {type: Object},
    collections: Array
  },
  watch:{
    new_collection: function(newVal){

      this.collection = newVal
      this.original_id = this.collection.id
      console.log(this.collection)
      this.main_photo_pre = ''
      if(this.collection.image_key !== '' && this.collection.image_key !== null)
      {
        this.main_photo_file.name = this.collection.image_key
        Storage.get(this.collection.image_key).then(url=>{
          this.main_photo_pre = url
        })
      }
      this.current_collections.clear()
      this.collections.forEach(collection=>{
        this.current_collections.add(collection.id)
      })
    }
  },
  data(){
    return{
      collection: {
        id: '',
        title: '',
        description: '',
        blurb: '',
        image_key: '',

      },
      main_photo_pre: '',
      main_photo_file: {name: ''},
      current_collections: new Set(),
      original_id: ''
    }
  },
  methods: {
    clearForm()
    {
      this.collection = {
        id: '',
            title: '',
            description: '',
            blurb: '',
            image_key: '',

      }
      this.main_photo_pre =''
      this.main_photo_file = {name: ''}

    },
    async addNewCollection(){
      console.log(this.collection)

      if(this.collection.title !=='')
      {
        this.collection.id = this.collection.title
            .trimStart()
            .trimEnd()
            .replaceAll(' ','-')
            .toLowerCase()
        if(this.collection.description.trimStart().trimEnd() !== '')
        {
          this.collection.description = this.collection.description.trimStart().trimEnd()
          this.collection.blurb = this.collection.blurb.trimEnd().trimStart()
          // Add photo to collection
          // Upload Collection to api
          if( this.original_id !== this.collection.id)
          {
            let file_name = 'collection_image/' + this.main_photo_file.name

            // New Collection ID found.
            delete this.collection.shop
            delete this.collection.shopCollectionId
            delete this.collection.product
            delete this.collection.updatedAt
            delete this.collection.createdAt
            console.log("Make New Collection")
            console.log("Adding new collection With photo...")
            console.log(file_name)
            if(this.main_photo_file.name === this.collection.image_key && this.collection.image_key !== '')
            {
              console.log("Not a new Photo...")
              this.collection.image_key = this.main_photo_file.name
            }
            else{
              console.log("New photo")
              let imagekey = await Storage.put(file_name,this.main_photo_file)
              this.collection.image_key = imagekey.key
            }
            let results = await addCollection(this.collection)
            console.log(results)
            let message = "Collections Updated..."
            this.$emit('collection',message)
          }
          else
          {
            if(this.main_photo_file.name !=='' || this.collection.image_key !== '')
            {
              let file_name = this.main_photo_file.name
              console.log(file_name)
              console.log(this.collection.image_key)
              if(file_name === this.collection.image_key && this.collection.image_key !== '')
              {
                // Just Update the Collection.....
                console.log("Updating collection but not the photo")
                delete this.collection.shop
                delete this.collection.product
                delete this.collection.updatedAt
                delete this.collection.createdAt
                let result = await API.graphql(graphqlOperation(updateCollection,
                    {input: this.collection}))
                console.log(result)
                let message = "Collections Updated..."
                this.$emit('collection',message)
              }
              else if(this.current_collections.has(this.collection.id))
              {
                let file_name = 'collection_image/' + this.main_photo_file.name
                // Update the photo as well as the API object.
                // Add Photo.
                let imagekey = await Storage.put(file_name,this.main_photo_file)
                this.collection.image_key = imagekey.key
                console.log("Updating Collection and photo")
                delete this.collection.shop
                delete this.collection.product
                delete this.collection.updatedAt
                delete this.collection.createdAt
                let result = await API.graphql(graphqlOperation(updateCollection,
                    {input: this.collection}))
                console.log(result)
                let message = "Collections Updated..."
                this.$emit('collection',message)

              }
            }
          }


        }

      }
    },
    async removeCollection(){
      let message = this.collection.title + " Was Deleted."
      await removeCollection(this.collection)
      this.$emit('collection',message)
    },
    onMaindrop(e)
    {
      console.log(e)
      console.log("Main Dropping")
      const bobcallback = (blob) =>{
        //blob.name = this.file_names.pop()
        let random_num = Math.floor(Math.random() *100000)
        let full_name = 'vased' + (this.main_file_name + random_num.toString()).hashCode().toString()
        blob.name = full_name
        this.main_photo_file = blob
        this.main_photo_pre = window.URL.createObjectURL(blob)
        console.log(blob)
      }
      e.preventDefault()
      if(e.dataTransfer.items)
      {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === 'file') {
            let file = e.dataTransfer.items[i].getAsFile();
            this.main_file_name = file.name
            const blobURL = URL.createObjectURL(file)
            const img = new Image()
            img.src = blobURL
            img.onerror = function(){
              URL.revokeObjectURL(this.src)
              console.log("Cannot load Image....")
            }
            img.onload = function () {
              URL.revokeObjectURL(this.src);
              const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
              const canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              canvas.toBlob(
                  bobcallback,
                  MIME_TYPE,
                  QUALITY
              );
            };
          }
        }
      }

    },
  }

}
</script>

<style scoped>
.main-drop{
  width: 430px;
  grid-column: 1/3;
  grid-row: 1/3;
  background: darkred;
  border: 3px solid green;
}
.drop-zone{
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  padding: 10px;
}
.preview-drop{
  grid-column: 1/3;
  grid-row: 3/5;
  background: darkorange;
  color: white;
  border: 4px solid cadetblue;
  padding: 10px;
}
.my-main-photo{
  padding: 5px 5px;
}
</style>
<template>
  <div>
    <div>
      <the-title-section/>
    </div>
    <div>
      <the-collections-section/>
    </div>
    <div>
      <the-info-one-section/>
    </div>
    <div>
      <the-featured-pieces-section/>
    </div>
    <div>
      <the-want-more-section/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TheTitleSection from "@/components/frontpage/components/TheTitleSection";
import TheCollectionsSection from "@/components/frontpage/components/TheCollectionsSection";
import TheInfoOneSection from "@/components/frontpage/components/TheInfoOneSection";
import TheFeaturedPiecesSection from "@/components/frontpage/components/TheFeaturedPiecesSection";
import TheWantMoreSection from "@/components/frontpage/components/TheWantMoreSection";
export default {
  name: 'TheDeskFront',
  components: {
    TheWantMoreSection,
    TheFeaturedPiecesSection,
    TheInfoOneSection,
    TheCollectionsSection,

    TheTitleSection
  },
}
</script>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Source+Serif+Pro:wght@300&display=swap');


</style>
<template>
  <div>
    <div v-if="collection !== null" class="collection-nav">
      <div class="collection-title">
        {{collection.title}}
      </div>
      <div class="collection-description">
        {{collection.description}}
      </div>
    </div>
    <div class="collection-detail-container">
      <div class="outer-container" v-for="product in products" :key="product">
        <the-product :product="product"/>
      </div>
    </div>
    <div v-if="no_products">
      <div class="general-title">
        <p>
          Products Coming Soon...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {getCollectionPage} from "@/methods/sitemethods";
import TheProduct from "@/components/TheProduct";

export default {
  name: "CollectionDetailView",
  components: {TheProduct},
  data()
  {
    return {
      collection: {},
      products: [],
      valid: true,
      no_products: false
    }
  },
  mounted() {
    this.collectionInsert()
  },
  methods:{
    async collectionInsert()
    {
      try {
        let collection_id = this.$route.params.slug
        collection_id = collection_id.toLowerCase()
        this.collection = await getCollectionPage(collection_id)
        this.products = this.collection.product.items
        if(this.products.length ===0)
          this.no_products = true
      }
      catch{
        this.valid = false
      }
    }
  }
}
</script>

<style scoped>
.collection-nav{
  display: flex;
  flex-direction: column;
  min-height:  110px;
  max-height: 150px;
  align-items: center;
  background-color: #ffb863;
  border-bottom: 1px black solid;
}
.collection-title{
  flex: 1;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 50px;
  letter-spacing: 4px;
  font-weight: 400;
  color: black;
}
.collection-description{
  flex: 1;
  text-align: center;
  font-family: 'Arimo', sans-serif;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 3px;
  word-spacing: 1px;
  line-height: 1.5;
}

.collection-detail-container{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-template-rows: auto;
  grid-gap: 20px;
  background-color: #FFFDD1;
}
.collection-detail-container>div{
  border: 1px black solid;
}
.outer-container{
  display: flex;

}
.general-title{
  font-family: 'Lexend Giga', sans-serif;
  font-size: 65px;
  font-weight: 500;
  color: black;
}

@media screen and (max-width: 700px){
  .collection-detail-container{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(360px,1fr));
    grid-template-rows: auto;
    background-color: #831200;
  }
  .general-title{
    font-family: 'Lexend Giga', sans-serif;
    font-size: 35px;
    font-weight: 500;
    color: black;
  }
}

</style>
<template>
  <div class="my-container">
    <div class="my-nav">
      <TheNav/>
    </div>
    <div class="main-view">
      <router-view v-slot="{Component, route}">
        <transition :name="'fade'">
          <component :is="Component" :key="route.path"/>
        </transition>
      </router-view>
    </div>
    <div class="my-footer">
      <TheFooter/>
    </div>
  </div>
</template>

<script setup>



</script>

<script>
import TheNav from "@/components/TheNav";
import TheFooter from "@/components/TheFooter";
export default {
  components: {TheNav, TheFooter}
}


</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;

}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  position: absolute;
  transform: translateX(200%);
}

.real-enter-active,
.real-leave-active{
  transition: all 2s ease;
}

.real-enter-from,
.real-leave-to{
  opacity: 0;
  position: absolute;
  transform: translatex(5%);
}

.my-container{
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  grid-auto-rows: minmax(70px, auto);


}
.my-nav{
  grid-column: 1/5;

  background: #831200;
  border-bottom: black 2px solid;

}
.main-view{
  grid-column: 1/5;

  background: white;

}
.my-footer{
  grid-column: 1/5;


}

@media screen and (max-width: 700px)
{
  .my-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(70px, auto);

  }
  .my-nav{
    grid-column: 1;
    grid-row: 1;
    background: #831200;

  }
  .main-view{
    grid-column: 1;
    grid-row: 2;
    background: white;

  }
  .my-footer{
    grid-column: 1;
    grid-row: 3;

  }
}

@import '../node_modules/bulma';
</style>

<template>
  <div class="about-info-area">
    <div class="about-title-area">
      <img class="about-photo" src="@/assets/photos/frontpage/imageThree.jpg">
    </div>
    <div class="about-about-area">
      <div class="about-about-area-title">
        È Raffinato
      </div>
      <div class="about-about-area-text">
          <p>
            It’s refined. True to the meaning of the word, ceramics are a result of a process of refinement.
          </p>
          <p>
            The clay is sourced from the ground, processed, formed, fired, and glazed to its final appearance.
          </p>
          <p>Once this has been done, the piece will last a lifetime and beyond.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheInfoOneSection"
}
</script>

<style lang="scss" scoped>
$orange-code: #e06f1f;
$yellow-code: #FFFDD1;
$red-code: #831200;
$brown-code: #ffb863;
.about-info-area{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(600px, auto);
  border-bottom: black 2px solid;
}
.about-title-area{
  position: relative;
  display: flex;
  height:auto;
  width: auto;


}
.about-photo{
  object-fit: cover;
  aspect-ratio: 2/1;
  flex: 1
}
.about-about-area{
  grid-column: 0;
  background-color:  #ffb863;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.about-about-area-title{
  font-family: 'Lexend Giga', sans-serif;
  background-color: #831200;
  color:white;
  border-bottom: solid black 1px;
  font-size: 60px;
  text-align: center;
}
.about-about-area-text{
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;
  margin: 80px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 23px;
  letter-spacing: 4px;
  word-spacing: 2px;
  line-height: 1.5;
}
.about-about-area-text>p{
  padding-top: 20px;
}


@media screen and (max-width: 700px) {
  .about-about-area-title{
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Lexend Giga', sans-serif;
    background-color: #831200;
    color:white;
    border-bottom: solid black 1px;
    font-size: 50px;
    text-align: center;
  }
  .about-info-area{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(600px, auto);
    border-bottom: black 2px solid;
  }
  .about-about-area-text{
    flex: 1;
    color: black;
    display: flex;
    flex-direction: column;
    margin: 35px;
    font-weight: 400;
    font-family: 'Arimo', sans-serif;
    font-size: 20px;
    letter-spacing: 4px;
    word-spacing: 2px;
    line-height: 1.5;
  }
}
</style>
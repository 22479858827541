/* eslint-disable */
import {defineStore} from 'pinia';
import {API, graphqlOperation} from "aws-amplify";
import {listCollections} from "@/graphql/queries";

export const useCollect = defineStore('collectList', {
    state: () =>({
        collect_list: []
    }),
    actions:{
        async makeCollections(){
            if(this.collect_list.length === 0)
            {
                this.collect_list = []
                console.log("Make Collections Called.")
                const results = await API.graphql(graphqlOperation(listCollections))
                const collections = results.data.listCollections.items
                collections.forEach(collection=>{
                    console.log(collection)
                })
            }
        }
    },
    getters: {
        getList(state)
        {
            return state.collect_list
        }
    }
})
<template>
    <the-desk-front/>
</template>

<script>
// @ is an alias to /src
import TheDeskFront from "@/components/frontpage/TheDeskFront";



export default {
  name: 'HomeView',
  data(){
    return{

    }
  },
  components: {

    TheDeskFront,

  },


}
</script>

<style lang="scss">


</style>


<template>
  <div class="product-view">
    <div v-if="product !== null">
      <div class="product-container">
        <div class="preview-photos">
          <div class="columns is-multiline">
            <div v-for="photo in preview_photos" :key="photo" class="column is-6" @click="selectPreview($event, photo)">
              <img class="image preview-image" :src="photo" alt="Whoops...">
            </div>
          </div>
        </div>
        <div class="main-photo">
          <div class="columns">
            <div class="column is-12">
              <img v-if="main_photo !==''" class="image image-class" :src="main_photo" alt="whoops">
            </div>
          </div>
        </div>
        <div class="info-container">
          <div class="my-title">
            {{product.name}}
          </div>
          <div class="my-description">
            {{product.description}}
          </div>
          <div class="myspecs columns">
            <div class="column is-6">
              <p>
                <span class="specifications"> Specifications: {{product.specifications}}</span>
              </p>
            </div>
            <div class="column is-6">
              <p>
                <span class="specifications">Product Dimensions: {{product.product_dimensions}}</span>
              </p>
            </div>
          </div>
          <div class="checkout-area">
            <div>
              <the-checkout :product="product"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Product Not Found.
    </div>
  </div>
</template>

<script>
import { getProductPage} from "@/methods/sitemethods";
import theCheckout from "@/components/TheCheckout";
import {Storage} from "aws-amplify";
export default {
  name: "ProductView",
  components: {theCheckout},
  data()
  {
    return {
      collection: {},
      product: {},
      valid: true,
      preview_photos: [],
      main_photo: '',

    }
  },
  mounted() {
    this.collectionInsert()
  },
  methods:{
    async collectionInsert()
    {
      try {
        let collection_id = this.$route.params.collection
        let product_id = this.$route.params.product
        product_id = product_id.toLowerCase()
        collection_id = collection_id.toLowerCase()
        //this.collection = await getCollectionPage(collection_id)
        console.log(collection_id)
        this.product = await getProductPage(product_id)
        this.getPhotos()
      }
      catch{
        this.valid = false
      }
    },
    getPhotos()
    {
      this.preview_photos = []
      if(this.product.preview !== undefined)
      {
        this.product.preview.items.forEach(photo=>{
          Storage.get(photo.image_keyy).then(url=>{
            this.preview_photos.push(url)
          })
        })
      }
      this.main_photo = ''
      console.log(this.product.image_key)
      if(this.product.image_key !== undefined)
      {
        Storage.get(this.product.image_key).then(url=>{
          this.main_photo = url
        })
      }
    },
    selectPreview(event, photo)
    {
      let temp = this.main_photo
      this.main_photo = photo
      for(let counter = 0; counter < this.preview_photos.length; counter++)
      {
        if(this.preview_photos[counter] === photo)
        {
          this.preview_photos[counter] = temp
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@300&display=swap');

.product-container{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-auto-rows: minmax(50px, auto);

  padding: 10px 10px;

  border-bottom: 5px solid black;
}
.product-container >div:nth-child(odd){

}
.product-container >div{

}

.preview-photos {
  padding: 10px 10px;
}
.main-photo{
  padding: 10px 10px;
}
.preview-image{
  border: 2px #4d0a00 solid;
  transition: all 0.3s ease-out;
}
.preview-image:hover,
.preview-image:focus{

  box-shadow: 10px 5px 25px #4d0a00;
}
.image-class{

  border: 2px #4d0a00 solid;

}

.info-container{
  color: white;
  padding: 10px 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(45px, auto);
  grid-gap: 10px;

}
.my-title{
  font-family: 'Lexend Giga', sans-serif;
  font-size: 40px;
  padding-left: 10px;
  font-weight: 400;
  color:white;
  background-color: #831200;
  grid-row: 1;
  border-radius: 2px;

}
.my-description{

  border-bottom: #831200 3px solid;
  font-family: 'Arimo', sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: black;
  grid-row:2;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  text-indent: 50px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 1.5;

}
.myspecs{
  grid-row: 3;
  font-family: 'Arimo', sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: black;
}
.specifications{
  font-weight: bolder;
}
.checkout-area{
  grid-row:4;
  color: black;
}

@media screen and (max-width: 700px)
{
  .product-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(50px, auto);
    padding: 0;
  }
  .my-title {
    padding-left: 0;
  }
  .info-container {
    color: white;
    padding: 15px 15px;
  }
  .preview-photos {
    padding: 25px 25px;
  }
  .main-photo{
    padding: 15px 15px;
  }

}


</style>
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const getShop = /* GraphQL */ `
    query GetShop($id: ID!) {
        getShop(id: $id) {
            id
            name
            collection {
                items {
                    id
                    title
                    description
                    blurb
                    image_key
                    createdAt
                    updatedAt
                    shopCollectionId
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
export const listShops = /* GraphQL */ `
    query ListShops(
        $filter: ModelShopFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                collection {
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getCollection = /* GraphQL */ `
    query GetCollection($id: ID!) {
        getCollection(id: $id) {
            id
            title
            description
            blurb
            image_key
            shop {
                id
                name
                collection {
                    nextToken
                }
                createdAt
                updatedAt
            }
            product {
                items {
                    id
                    name
                    description
                    blurb
                    price
                    stripe_id
                    available
                    image_key
                    specifications
                    product_dimensions
                    shipping_cost
                    createdAt
                    updatedAt
                    collectionProductId
                    preview {
                        items {
                            id
                            image_keyy
                            createdAt
                            updatedAt
                            productPreviewId
                        }
                        nextToken
                    }
                }
                nextToken
            }
            createdAt
            updatedAt
            shopCollectionId
        }
    }
`;
export const listCollections = /* GraphQL */ `
    query ListCollections(
        $filter: ModelCollectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                title
                description
                blurb
                image_key
                shop {
                    id
                    name
                    createdAt
                    updatedAt
                }
                product {
                    items {
                        id
                        name
                        description
                        blurb
                        price
                        stripe_id
                        available
                        image_key
                        specifications
                        product_dimensions
                        shipping_cost
                        createdAt
                        updatedAt
                        collectionProductId
                        preview {
                            items {
                                id
                                image_keyy
                                createdAt
                                updatedAt
                                productPreviewId
                            }
                            nextToken
                        }
                    }
                    nextToken
                }
                createdAt
                updatedAt
                shopCollectionId
            }
            nextToken
        }
    }
`;
export const getProduct = /* GraphQL */ `
    query GetProduct($id: ID!) {
        getProduct(id: $id) {
            id
            collection {
                id
                title
                description
                blurb
                image_key
                shop {
                    id
                    name
                    createdAt
                    updatedAt
                }
                product {
                    nextToken
                }
                createdAt
                updatedAt
                shopCollectionId
            }
            name
            description
            blurb
            price
            stripe_id
            available
            image_key
            specifications
            product_dimensions
            shipping_cost
            preview {
                items {
                    id
                    image_keyy
                    createdAt
                    updatedAt
                    productPreviewId
                }
                nextToken
            }
            createdAt
            updatedAt
            collectionProductId
        }
    }
`;
export const listProducts = /* GraphQL */ `
    query ListProducts(
        $filter: ModelProductFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                collection {
                    id
                    title
                    description
                    blurb
                    image_key
                    createdAt
                    updatedAt
                    shopCollectionId
                }
                name
                description
                blurb
                price
                stripe_id
                available
                image_key
                specifications
                product_dimensions
                shipping_cost
                preview {
                    nextToken
                }
                createdAt
                updatedAt
                collectionProductId
            }
            nextToken
        }
    }
`;
export const getPreview = /* GraphQL */ `
    query GetPreview($id: ID!) {
        getPreview(id: $id) {
            id
            image_keyy
            product {
                id
                collection {
                    id
                    title
                    description
                    blurb
                    image_key
                    createdAt
                    updatedAt
                    shopCollectionId
                }
                name
                description
                blurb
                price
                stripe_id
                available
                image_key
                specifications
                product_dimensions
                shipping_cost
                preview {
                    nextToken
                }
                createdAt
                updatedAt
                collectionProductId
            }
            createdAt
            updatedAt
            productPreviewId
        }
    }
`;
export const listPreviews = /* GraphQL */ `
    query ListPreviews(
        $filter: ModelPreviewFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPreviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                image_keyy
                product {
                    id
                    name
                    description
                    blurb
                    price
                    stripe_id
                    available
                    image_key
                    specifications
                    product_dimensions
                    shipping_cost
                    createdAt
                    updatedAt
                    collectionProductId
                }
                createdAt
                updatedAt
                productPreviewId
            }
            nextToken
        }
    }
`;
export const getOrders = /* GraphQL */ `
  query GetOrders($id: ID!) {
    getOrders(id: $id) {
      id
      shipping
      shipped
      checkout_id
      name
      email
      phone
      city
      country
      line1
      line2
      postal_code
      state
      products
      description
      other
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shipping
        shipped
        checkout_id
        name
        email
        phone
        city
        country
        line1
        line2
        postal_code
        state
        products
        description
        other
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

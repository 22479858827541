
<template>
  <div class="columns" @dragover.prevent @drop.prevent >
    <div class="column is-6" >
      <p class="title">
        Update A Product
      </p>
      <p class="subtitle my-2">Collection: {{collection.id}}</p>
      <p class="subtitle my-1">Id: {{ product.id}}</p>
      <p class="subtitle my-1">Name: {{product.name.trimStart().trimEnd()}}</p>
      <input class="input is my-2" v-model="product.name">
      <p class="subtitle my-1">Description: {{product.description}}</p>
      <textarea class="textarea is my-2" v-model="product.description"/>
      <p class="subtitle my-1">Blurb: {{product.blurb}}</p>
      <input class="input is my-2" v-model="product.blurb">

      <p class="subtitle my-1">Specifications: {{product.specifications}}</p>
      <input class="input is my-2" v-model="product.specifications">

      <p class="subtitle my-1">Dimensions: {{product.product_dimensions}}</p>
      <input class="input is my-2" v-model="product.product_dimensions">

      <input class="radio mx-2" type="radio" id="true" :value="true" v-model="product.available">
      <label for="true">Available</label>
      <input class="radio mx-2" type="radio" id="false" :value="false" v-model="product.available">
      <label for="false">Unavailable</label>
      <br>
      <input class="my-2 mx-2" type="number" id="price" v-model="product.price">
      <label class="subtitle my-2" for="price">Enter In A Price.</label>
      <p v-if="product.price !==0" class="subtitle  mx-2 my-2">Actual Price  {{(product.price /100).toLocaleString('en-US',{
        style:'currency',
        currency:'USD'})}}</p>
      <input class="my-2 mx-2" type="number" id="shipping" v-model="product.shipping_cost">
      <label class="subtitle my-2" for="shipping">Enter Shipping Cost.</label>
      <p v-if="product.shipping_cost !==0" class="subtitle mx-2 my-2">Actual Ship Price {{(product.shipping_cost /100).toLocaleString('en-US',{
        style:'currency',
        currency:'USD'})}}</p>

      <button class="my-2 mx-2 button is-fullwidth is-dark" @click="onSubmit">
        Update Product
      </button>
      <button class="my-2 mx-2 button is-fullwidth is-danger" @click="onDelete">
        Delete Product...
      </button>
    </div>
    <div class="column is-6">

      <div class="drop-zone">
        <div class="main-drop" @drop="onMaindrop">
          <p class="subtitle has-text-white">
            Drag main photo here.
          </p>
          <img v-if="main_photo_pre !==''" class="image my-main-photo" :src="main_photo_pre" alt="whoops">
        </div>
        <div class="preview-drop" @drop="onPreviewdrop">
          <p class="subtitle">
            Drag Preview Photos
          </p>
          <div class="columns is-multiline">
            <div class="pre-photos column is-6" v-for="photo in preview_photos_pre" :value="photo" :key="photo">
              <img :src="photo" alt="Whoops">
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <button class="my-2 mx-2 button is-success is-fullwidth">
            Update Product Photos
          </button>
        </div>
        <div class="column is-6">
          <button class="my-2 mx-2 button is-danger is-fullwidth">
            Clear Photos
          </button>
        </div>
      </div>
      <p class="title is-success">
        {{status}}
      </p>
      <div v-if="form_error.length !== 0">
        <p class="title has-text-danger">Could Not Submit Product</p>
      </div>
      <div v-for="errors in form_error" :key="errors">
        <p class="subtitle has-text-danger-dark my-2" >
          *{{errors}}
        </p>
      </div>
      <div class="subtitle">

      </div>
    </div>
  </div>
</template>

<script>
import {
  addStripeProduct,

  calculateSize, deleteProducts, updateCurrentProduct


} from "@/methods/formmethods";
import {Storage} from "aws-amplify";
import axios from "axios";
const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;
const MIME_TYPE = "image/jpeg";
const QUALITY = .90;


export default {
  name: "TheUpdate",
  props:{
    collection: {type: Object},
    input_product: {type: Object}
  },
  watch:{
    input_product: function(newVal){
      this.product = newVal
      this.original_price = this.input_product.price
      this.product.price = this.product.price /100
      this.product.shipping_cost = this.product.shipping_cost /100
      this.setPhotos()
      this.status = ''
      this.product.preview.items.forEach(photo=>{
        this.original_preview_photos.add(photo)
      })
      this.original_photo_name = this.product.image_key
    }
  },
  data(){
    return{
      product: {
        id: '',
        name: '',
        description: '',
        blurb: '',
        available: false,
        specifications: '',
        product_dimensions: '',
        shipping_cost: 0,
        price: 0,
        collectionProductId: ''},

      main_photo_pre: '',
      main_photo_file: {name: ''},
      preview_photos_pre: [],
      preview_photo_names: [],
      preview_photo_files: [],

      blurb_limit: 100,
      description_limit: 500,
      title_limit: 30,
      specifications_limit: 100,
      dimensions_limit: 100,
      form_error: [],

      original_photos: new Set(),

      delete_final: false,
      status: '',

      original_photo_name: '',
      original_preview_photos: new Set(),

      original_price: 0



    }
  },
  mounted() {
    this.setProduct()
  },
  methods:{
    setProduct(){
      this.product = this.input_product
      this.original_price = this.input_product.price
      this.product.price = this.product.price /100
      this.product.shipping_cost = this.product.shipping_cost /100
      this.setPhotos()
      this.status = ''
      this.product.preview.items.forEach(photo=>{
        this.original_preview_photos.add(photo)
      })
      this.original_photo_name = this.product.image_key

    },
   async setPhotos()
    {
      this.original_photos.clear()
      this.main_photo_file ={}
      this.main_photo_pre = ''
      this.preview_photo_names = []
      this.preview_photo_files = []
      this.preview_photos_pre = []
      this.main_photo_file  = this.product.image_key
      this.main_photo_pre= await Storage.get(this.product.image_key)
      this.preview_photo_names = this.product.preview.items
      this.product.preview.items.forEach(photo=>{
        this.original_photos.add(photo.id)
        Storage.get(photo.image_keyy).then(url=>{
          this.preview_photos_pre.push(url)
        })
      })
    },
    async onDelete()
    {
      this.status = "Deleting Product...."
      this.delete_final = await deleteProducts(this.product)
      this.status = "Product Deleted..."
      this.product = {
      id: '',
          name: '',
          description: '',
          blurb: '',
          available: false,
          specifications: '',
          product_dimensions: '',
          shipping_cost: 0,
          price: 0,
          collectionProductId: ''}
      this.$emit('update','Product was deleted.')
    },
    async onSubmit()
    {
      this.form_error = []
      this.product.collectionProductId = this.collection.id
      if(this.product.name.length >= this.title_limit)
        this.form_error.push("Title Name too Long.")

      if(this.product.description.trimEnd().length < this.description_limit)
        this.product.description = this.product.description.trimEnd().trimStart()
      else
        this.form_error.push("Description too Long")

      if(this.product.blurb.length < this.blurb_limit)
        this.product.blurb = this.product.blurb.trimEnd().trimStart()
      else
        this.form_error.push("Blurb Too long...")

      if(this.product.specifications.length < this.specifications_limit)
        this.product.specifications = this.product.specifications.trimEnd().trimStart()
      else
        this.form_error.push("Specification Too long...")

      if(this.product.product_dimensions.length < this.dimensions_limit)
        this.product.product_dimensions = this.product.product_dimensions.trimEnd().trimStart()
      else
        this.form_error.push("Product Dimension Too long...")

      if(this.product.price <= 0)
        this.form_error.push("Price Zero or lower")

      if(this.product.shipping_cost <= 0)
        this.form_error.push("Shipping Price Zero or lower")
      if(this.product.name === '')
      {
        this.form_error.push("Name is empty")
      }
      if(this.product.description === '')
      {
        this.form_error.push("Description is empty")
      }
      if(this.product.blurb === '')
        this.form_error.push("Blurb is empty")
      if(this.product.specifications === '')
        this.form_error.push("Specs is empty")
      if(this.product.product_dimensions === '')
        this.form_error.push("Dimensions is empty")
      if(this.form_error.length === 0)
      {
        // Do the Work
        console.log(this.product.price)
        console.log(this.original_price)
        if(this.product.price !==this.original_price)
        {
          console.log("Create New Price")
          let stripe_data = await addStripeProduct(this.product)
          this.product.stripe_id = stripe_data.id
          this.product = await updateCurrentProduct(this.product)
        }
        else
        {
          console.log("Just Update Product in Stripe.")
          let response = await axios.post("https://dgegk68pf8.execute-api.us-east-1.amazonaws.com/updateProduct",this.product)
          console.log(response)
          this.product = await updateCurrentProduct(this.product)
        }

        /*
                let stripe_data = await addStripeProduct(this.product)
        this.product.stripe_id = stripe_data.id
        this.product = await updateCurrentProduct(this.product)
         */

        /*
        this.product.image_key = await createMainPhoto(this.main_photo_file)
        this.product = await addProductMethod(this.product)
        console.log(this.product)
        await updatePreviewPhotos(this.preview_photo_files, this.product )
        console.log("Finished Uploading New Product")

         */

        let message = this.product.name + " Was Updated"
        this.$emit('update',message)

      }
    },
    onMaindrop(e)
    {
      console.log(e)
      console.log("Main Dropping")
      const bobcallback = (blob) =>{
        //blob.name = this.file_names.pop()
        let random_num = Math.floor(Math.random() *100000)
        let full_name = 'vased' + (this.main_file_name + random_num.toString()).hashCode().toString()
        blob.name = full_name
        this.main_photo_file = blob
        this.main_photo_pre = window.URL.createObjectURL(blob)
        console.log(blob)
      }
      e.preventDefault()
      if(e.dataTransfer.items)
      {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === 'file') {
            let file = e.dataTransfer.items[i].getAsFile();
            this.main_file_name = file.name
            const blobURL = URL.createObjectURL(file)
            const img = new Image()
            img.src = blobURL
            img.onerror = function(){
              URL.revokeObjectURL(this.src)
              console.log("Cannot load Image....")
            }
            img.onload = function () {
              URL.revokeObjectURL(this.src);
              const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
              const canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              canvas.toBlob(
                  bobcallback,
                  MIME_TYPE,
                  QUALITY
              );
            };
          }
        }
      }

    },
    onPreviewdrop(e)
    {
      console.log(e)
      console.log("Preview Dropping")
      const bobcallback = (blob) =>{
        //blob.name = this.file_names.pop()
        let random_num = Math.floor(Math.random() *100000)
        let full_name = 'vased' + (this.preview_photo_names.pop() + random_num.toString()).hashCode().toString()
        blob.name = full_name
        this.preview_photo_files.push(blob)
        let comp_photo_url = window.URL.createObjectURL(blob)
        this.preview_photos_pre.push(comp_photo_url)
        console.log(blob)

      }
      e.preventDefault()
      if(e.dataTransfer.items)
      {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === 'file') {
            let file = e.dataTransfer.items[i].getAsFile();
            this.preview_photo_names.push(file.name)
            const blobURL = URL.createObjectURL(file)
            const img = new Image()
            img.src = blobURL
            img.onerror = function(){
              URL.revokeObjectURL(this.src)
              console.log("Cannot load Image....")
            }
            img.onload = function () {
              URL.revokeObjectURL(this.src);
              const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
              const canvas = document.createElement("canvas");
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              canvas.toBlob(
                  bobcallback,
                  MIME_TYPE,
                  QUALITY
              );
            };
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.drop-zone{
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  padding: 10px;
}
.main-drop{
  width: 430px;
  grid-column: 1/3;
  grid-row: 1/3;
  background: darkred;
  border: 3px solid green;
}
.preview-drop{
  grid-column: 1/3;
  grid-row: 3/5;
  background: darkorange;
  color: white;
  border: 4px solid cadetblue;
  padding: 10px;
}
.my-main-photo{
  padding: 5px 5px;
}
</style>
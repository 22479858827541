<template>
  <div class="the-order">
    <div class="column">
      <p class="subtitle" style="border-bottom: solid black 2px;">
        Order ID: {{order.id}}
      </p>
      <p class="subtitle">
        Customer Name: {{order.name}}<br>
        Email: {{order.email}}
      </p>
      <p class="subtitle" style="border-top: 2px solid black;
       border-bottom: 2px solid black; padding-top: 10px; padding-bottom: 10px;">
        Shipping information: <br>
        Street: {{order.line1}} <br>
        Street {{order.line2}} 2: <br>
        City: {{order.city}} <br>
        State: {{order.state}} <br>
        Zip: {{order.postal_code}}<br>
        Country: {{order.country}}<br>
      </p>

      <p class="title">
        Ready To ship?
      </p>
      <p class="subtitle">Input Tracking Number: {{local_tracking}}</p>
      <p v-if="local_tracking !== ''" class="has-text-danger" style="font-size: 13px">
        Warning: updating the tracking will send customer an email.
      </p>
      <input class="input" v-model="local_tracking">
      <button class="my-2 button is-success is-medium" @click="sendTracking">
        Update Tracking
      </button>
      <p :class="{'has-text-success': !error, 'has-text-danger':error}" style="font-size: 20px;">
        {{this.email_status}}
      </p>
      <p class="subtitle">
        Products Ordered:
      </p>
      <div v-for="products in order.order_products" :key="products">
        {{products}}
      </div>
    </div>
  </div>
</template>

<script>
import {sendEmail, updateShippingOrder} from "@/methods/formmethods";
import {Storage} from "aws-amplify";
import {getProductPage} from "@/methods/sitemethods";

export default {
  name: "TheOrder",
  props:{
    new_order: {type: Object}
  },
  data(){
    return{
      order: {
        id: '',
        shipping: '',
        shipped: '',
        checkout_id: '',
        name: '',
        email: '',
        phone: '',
        city: '',
        country: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
        products: [],
        description: '',
        other: '',
      },
      products: [],
      product: {},
      local_tracking: '',
      email_status: '',
      error: false,

    }
  },
  watch:{
    new_order: function(newValue){
      this.local_tracking = ''
      console.log("new Value for days")
      console.log(newValue)
      this.order = newValue
      this.email_status = ''
      this.products = this.order.order_products
    }
  },
  mounted() {
    this.order = this.new_order
    this.products = this.order.order_products
  },
  methods:{
    async getProductPhoto()
    {
      console.log(this.products)
      let product = await getProductPage(this.products[0].product_id)
      this.product = product
      return await Storage.get(product.image_key, {expires:600000 })
    },
    async sendTracking(){
      console.log("Sending tracking info.")
      if(this.local_tracking !== '')
      {
        let warning = "Warning: This Will Send the customer an email with " +
            "the tracking number provided."
        if(confirm(warning) === true){
          console.log("Send Customer tracking info")
          this.order.tracking = this.local_tracking
          this.order.product_image = await this.getProductPhoto()
          this.order.product_name = this.product.name
          this.order.price = (this.product.price /100).toLocaleString('en-US',{
          style:'currency',
          currency: 'USD'})
          this.order.shipping = (this.product.shipping_cost /100).toLocaleString('en-US',{
          style:'currency',
          currency: 'USD'})
          this.order.total_price = (this.product.shipping_cost + this.product.price) / 100
          this.order.total_price = this.order.total_price.toLocaleString('en-US',{
            style:'currency',
            currency: 'USD'})
          let response_data = await sendEmail(this.order)
          this.error = response_data.error
          this.email_status = response_data.status
          if(!this.error)
          {
            console.log("update graph Ql")
            await updateShippingOrder(this.order)
          }
        }
        else{
          console.log("Track Send Aborted.")
        }
      }
      else{
        alert("Tracking Info not set.")
      }
    }
  }

}
</script>

<style scoped>

</style>
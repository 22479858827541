/* eslint-disable */
import {
    createShop,
    createCollection,
    createProduct,
    updateProduct,
    createPreview,
    deletePreview, deleteProduct, deleteCollection, updateOrders,
} from "@/graphql/mutations";
import {API, graphqlOperation, Storage} from "aws-amplify";
import {listCollections, getCollection} from "@/graphql/queries";
import axios from "axios";


export async function addCollection(collection) {
    console.log(collection)
    const result = await API.graphql(graphqlOperation(createCollection, {input: collection}))
    return result
}
export async function getCollections()
{
    let collection_list = []
    const results = await API.graphql(graphqlOperation(listCollections))
    const collections = results.data.listCollections.items
    collections.forEach(collection=>{
        collection_list.push(collection)
    })
    return collection_list

}
export async function getProducts(selected_collection)
{
    console.log("Selected Collection: " + selected_collection.id )
    let my_id = selected_collection.id
    let result = await API.graphql(graphqlOperation(getCollection,{id: my_id}))
    result = result.data.getCollection.product.items
    return result
}
export function makingShop()
{
    console.log("Created Shop Name")
    API.graphql(graphqlOperation(createShop, {input:{id: 1, name:'vased'}})).then(
        results=>{
            console.log("Sucess")
            console.log(results)
        }
    ).catch(error=>{
        console.log("Mega Error")
        console.log(error)
    })
}

export function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;
    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    return [width, height];
}

export async function addProductMethod(product)
{
    // still need main photo url
    // And need the preview URLS
    let result = await API.graphql({
        query: createProduct,
        variables:{input:product}
    })
    result = result.data.createProduct
    return result

}

export async function updateMainPhoto( file, product)
{
    console.log("In Update Main Photo Func")

    if(product.image_key !== null)
    {
        console.log("Deleting Old photo")
        let error = await Storage.remove(product.image_key)
        console.log(error)
    }
    console.log("updating Photo....")
    let result = await Storage.put(file.name, file, {
        contentType: "image/png"
    })
    console.log(result)
    let result_data = await API.graphql(graphqlOperation(updateProduct, {input: {id: product.id,
            image_key: result.key}} ))

    return result_data.data.updateProduct

}
export async function updateCurrentProduct(product)
{
    console.log("Updating Product In stripe")

    console.log("Updating Product In database")
    let result = await API.graphql(graphqlOperation(updateProduct, {input: {id: product.id,
        name:product.name, description:product.description, blurb: product.blurb, price: product.price,
        stripe_id: product.stripe_id, available: product.available, image_key: product.image_key, specifications: product.specifications,
        product_dimensions: product.product_dimensions, shipping_cost: product.shipping_cost}}))
    return result.data.updateProduct
}
export async function createMainPhoto(file)
{
    console.log("Uploading Photo to s3....")
    let result = await Storage.put(file.name, file, {
        contentType: "image/png"
    })
    return result.key
}

export async function updatePreviewPhotos(files, product)
{
    let preview_list = []
    for(let counter = 0; counter < files.length; counter++)
    {
        let file_name = 'preview/'+ files[counter].name
        let result = await Storage.put(file_name, files[counter],
            {
                contentType: "image/png"
            })
        console.log(result)
        let preview = await API.graphql(graphqlOperation(createPreview, {input:{id: files[counter].name,
                image_keyy: result.key, productPreviewId: product.id }}))
        preview_list.push(preview)
    }
    return preview_list

}

export async function deleteProducts(product)
{
    // WILL Delete All photos associated
    // WILL Delete the product and other tings.....
    console.log("Starting Delete Process....")
    console.log("Deleting Product: " + product.id)
    if(product.preview !== undefined)
    {
        for(let counter = 0; counter < product.preview.items.length; counter++)
        {
            await Storage.remove(product.preview.items[counter].image_keyy)
            await API.graphql(graphqlOperation(deletePreview, {input: {id: product.preview.items[counter].id}}))
        }
    }
    await Storage.remove(product.image_key)
    await API.graphql(graphqlOperation(deleteProduct, {input: {id: product.id}}))
    console.log("Done Removing Product......")
    return true
}

export async function removeCollection(collection)
{
    console.log("Deleteing Collection And photos")
    // Delete photo ascociatted with product
    console.log(collection.product.items)
    if(collection.product.items.length !== 0)
    {
        for(let counter = 0 ; counter < collection.product.items.length; counter++)
        {
            await deleteProducts(collection.product.items[counter])
        }
    }
    if(collection.image_key !== null)
        await Storage.remove(collection.image_key)
    console.log("We got heere in the delete process...")
    await API.graphql(graphqlOperation(deleteCollection, {input: {id: collection.id}}))
    console.log("we Finished deleting...")

}

export async function addStripeProduct(product)
{
    // This will add the product to stripe and update
    // the product in GraphQL with the Stripe ID
    console.log("Testing Stripe.....")
    let response = await axios.post("https://dgegk68pf8.execute-api.us-east-1.amazonaws.com/createProduct",product)
    console.log(response.data)
    return response.data
}

export async function sendEmail(email)
{
    console.log("sending Email")
    let response = await axios.post("https://dgegk68pf8.execute-api.us-east-1.amazonaws.com/sendEmail",email)
    console.log("Our Response")
    console.log(response)
    console.log(response.data)
    return response.data
}

export async function updateShippingOrder(order)
{
    console.log("updating order database")
    let response = await API.graphql(graphqlOperation(updateOrders, {input: {id:order.id,
        shipped: true, shipping:order.tracking}}))
    console.log(response)

}
<template>
  <div class="want-more-container">
    <div class="more-info-title">
      <p>
        Want More?
      </p>
      <router-link class="button display-button"  :to="{name: 'all_products'}">
        View All
      </router-link>
    </div>
    <div class="want-more-area">
      <div class="want-more-info" >
        <div class="want-more-info-text">
          <div class="want-more-info-text-title">
            Commissioned Pieces
          </div>
          <p>
            We are happy to offer the opportunity for commissioned pieces.
          </p>
          <br>
          <p>
            Please get in contact with us about special requests.
          </p>
          <p>
            We are delighted to oblige.
          </p>
          <p>
            <button class="button display-button">
              Contact Us
            </button>
          </p>
        </div>
      </div>
      <div class="want-more-photo-container">
        <img class="want-more-photo" src="@/assets/photos/frontpage/imageOne.jpg">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TheWantMoreSection"
}
</script>

<style scoped>
.want-more-container{
  display: flex;
  flex-direction: column;

}
.want-more-area{
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: minmax(600px, auto);
}
.want-more-info{

  border-right: solid black 2px;
}
.more-info-title{
  flex: 1;
  text-align: center;
  font-family: 'Lexend Giga', sans-serif;
  background-color: #ffb863;
  color:black;
  border-bottom: solid black 1px;
  font-size: 60px;
}

.want-more-info-text{
  color: black;
  display: flex;
  flex-direction: column;
  margin: 80px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 23px;
  letter-spacing: 4px;
  word-spacing: 2px;
  line-height: 1.5;
}
.want-more-info-text-title{
  font-weight: 400;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 45px;
  letter-spacing: 4px;
  word-spacing: 2px;
  line-height: 1.5;
}
.want-more-info-text>p{
  padding-top: 30px;
}

.want-more-photo-container{

  grid-row: 1;
  display: flex;

}
.want-more-photo{
  object-fit: cover;
  aspect-ratio: 2/1;
  flex: 1;
  border: solid black 1px;
}


.display-button{


  font-family: 'Arimo', sans-serif;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
  background-color: #ffb863;
  color: black;
  border: 2px solid black;
  text-decoration: none;
  transition: all 0.5s ease-out;

}
.display-button:hover,
.display-button:focus{
  background-color: #831200;
  color: white;
}

@media screen and (max-width: 700px) {
  .want-more-area{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(600px, auto);
  }
  .more-info-title{
    flex: 1;
    text-align: center;
    font-family: 'Lexend Giga', sans-serif;
    background-color: #ffb863;
    color:black;
    border-bottom: solid black 1px;
    font-size: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .want-more-info-text{
    color: black;
    display: flex;
    flex-direction: column;
    margin: 5px;
    font-weight: 400;
    font-family: 'Arimo', sans-serif;
    font-size: 23px;
    letter-spacing: 4px;
    word-spacing: 2px;
    line-height: 1.5;

  }
  .want-more-info-text-title{
    font-weight: 400;
    font-family: 'Lexend Giga', sans-serif;
    font-size: 30px;
    letter-spacing: 4px;
    word-spacing: 2px;
    line-height: 1.5;
  }
}
</style>
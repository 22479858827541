import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AddProduct from "@/views/products/AddProduct";
import LoginView from "@/views/admin/LoginView";
import CollectionView from "@/views/collections/CollectionView";
import CollectionDetailView from "@/views/collections/CollectionDetailView";
import ProductView from "@/views/products/ProductView";
import AllProductsView from "@/views/products/AllProductsView";
import OrdersView from "@/views/admin/OrdersView";
import CustomerOrderView from "@/views/CustomerOrderView";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      navbar: false,

    }
  },
  {
    path:'/controlzone',
    name: 'controlzone',
    component: AddProduct,
    meta: {
      navbar: true,

    }
  },
  {
    path:'/login',
    name: 'log_in',
    component: LoginView,
    meta: {
      navbar: true,

    }
  },
  {
    path:'/shop/collections',
    name: 'shop_collections',
    component: CollectionView,
    meta: {
      navbar: true,

    }
  },
  {
    path:'/shop/collections/:slug',
    name: 'shop_collection_detail',
    component: CollectionDetailView,
    meta: {
      navbar: true,

    }
  },
  {
    path: '/shop/collections/:collection/:product',
    name: 'shop_product',
    component: ProductView,
    meta: {
      navbar: true,

    }
  },
  {
    path: '/shop/products/all',
    name: 'all_products',
    component: AllProductsView,
    meta: {
      navbar: true,

    }
  },
  {
    path: '/controlzone/orders',
    name: 'orders_view',
    component: OrdersView,
    meta: {
      navbar: true,

    },
  },
  {
    path: '/customer/orders/:ordernumber',
    name: 'customer_order',
    component: CustomerOrderView,
    meta: {
      navbar: true,
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class="product-glance-area">
    <div class="product-title">
      Featured Pieces
    </div>
    <div class="product-selection-area">
        <div v-for="product in product_list" :key="product.id" style="display: flex; border: black 1px solid;">
          <the-product :product="product"/>
        </div>
    </div>
  </div>
</template>

<script>
import {API, Storage} from "aws-amplify";
import {listProducts} from "@/graphql/queries";
import TheProduct from "@/components/TheProduct";

export default {
  name: "TheFeaturedPiecesSection",
  components: {TheProduct},
  data(){
    return{
      product_list: [],

    }
  },
  mounted() {
    this.getHomeProducts()
  },
  methods: {
    async getHomeProducts() {
      let results = await API.graphql({query: listProducts, variables: {limit: 4}})
      this.product_list = results.data.listProducts.items

      this.product_list.forEach((product, index) => {
        Storage.get(product.image_key).then(url => {
          this.product_list[index].url = url
        })
      })
    },
    async getPhoto(imageKey) {
      return await Storage.get(imageKey)
    }
  }
}
</script>

<style scoped>
.product-glance-area{
  display: flex;
  flex-direction: column;
  background-color:  #ffb863;


}
.product-title{
  flex: 1 110px;
  background-color: #831200;
  font-family: 'Lexend Giga', sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  color: white;
  font-size: 70px;
  text-align: center;
  border-bottom: black solid 2px;
}
.product-selection-area{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-template-rows: auto auto;
  grid-column-gap: 20px;


}

@media screen and (max-width: 700px) {
  .product-selection-area{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
    grid-template-rows: auto auto;
    grid-column-gap: 20px;


  }
  .product-title{
    flex: 1 110px;
    background-color: #831200;
    font-family: 'Lexend Giga', sans-serif;
    font-weight: 700;
    letter-spacing: 5px;
    color: white;
    font-size: 50px;
    text-align: center;
    border-bottom: black solid 2px;
  }
}


</style>
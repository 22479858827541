<template>
  <div>
    <div class="collection-nav">
      <div class="collection-title">
        All Products
      </div>
    </div>
    <div class="all-products-container">

      <div class="outer-container" v-for="product in products" :key="product">
        <the-product :product="product"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllProducts} from "@/methods/sitemethods";
import TheProduct from "@/components/TheProduct";

export default {
  name: "AllProductsView",
  components: {TheProduct},
  data(){
    return{
      products: [],

    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    async getProducts()
    {
      this.products = await getAllProducts()
    }
  }
}
</script>

<style scoped>
.collection-nav{
  display: flex;
  flex-direction: column;
  min-height:  100px;
  max-height: 110px;
  align-items: center;
  background-color: #ffb863;
  border-bottom: 1px black solid;
}
.collection-title{
  flex: 1;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 50px;
  letter-spacing: 4px;
  font-weight: 400;
  color: black;
}
.all-products-container{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-template-rows: auto;
  grid-gap: 20px;
  background-color: #FFFDD1;
}
.all-products-container>div{
  border: 1px black solid;
}
.outer-container{
  display: flex;

}
@media screen and (max-width: 700px){
  .all-products-container{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(360px,1fr));
    grid-template-rows: auto;
    background-color: #831200;
  }
}

</style>
<template>
  <div class="footer-container">
    <div v-if="auth.route === 'authenticated'">
      <div class="columns">
        <div class="column">
          <router-link :to="{name: 'log_in'}">
            Sign Out / Manage Your Stuff
          </router-link>
        </div>
        <div class="column">
          <router-link :to="{name: 'controlzone'}">
            Add A Product
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useAuthenticator} from "@aws-amplify/ui-vue";

const auth = useAuthenticator()
import { Auth } from 'aws-amplify';

export default {
  name: "TheFooter",
  data(){
    return{
      auth,
    }
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        console.log("sign out Successfully")
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  }
}
</script>

<style scoped>

</style>
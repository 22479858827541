<template>
  <div>
    <div class="title-container-title">
      <p class="head-title">
        Terra Raffinata
      </p>
      <p class="head-title" style="font-size: 2vw;">
        Refined Earth Pottery
      </p>
    </div>
    <div class="title-section-container">
      <div class="title-container">
        <div class="title-container-text" style="align-self: center;">
          <div class="title-container-text-title">
            Welcome to the ornate world of <br>
            <span style="font-family: 'Orbitron', sans-serif; letter-spacing: 5px; font-size: 45px;">
              Terra Raffinata
            </span>
          </div>
          <p>We take pleasure in converting the raw, muddy earth into fine ceramics.</p>
          <p>It is our desire to produce functional pieces of art to engage the senses.</p>
          <p>All our dinnerware and mugs are food safe </p>
          <div class="columns my-3">
            <div class="column is-6">
              <button class="button display-button" style="width: 300px;">
                About Us
              </button>
            </div>
            <div class="column is-6">
              <button class="button display-button" style="width: 300px;">
                Our Process
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="title-photo-container">
        <img class="title-image" src="@/assets/photos/frontpage/imageTwo.jpg" alt="whoops">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTitleSection"
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Orbitron:wght@700&display=swap');



.title-container-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:  #ffb863;
  border-bottom: black solid 2px;

}
.head-title{
  font-size: 4vw;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 10px;
  font-weight: 500;
  color: black;
}

.title-section-container{
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.title-container{

  border-right:  black 4px solid;
  display: flex;
  flex-direction: column;
  min-height: 650px;



}
.title-container-text-title{

  font-weight: 400;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 35px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 1.5;
}
.title-container-text{
  flex: 1;
  margin: 80px;
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 23px;
  letter-spacing: 4px;
  word-spacing: 2px;
  line-height: 1.5;

}
.title-container-text>p{
  padding-top: 30px;
}

.title-photo-container{

  display: flex;
  min-height: 650px;
}
.title-image{
  object-fit: cover;
  aspect-ratio: auto 1/1;
  flex: 1

}

.display-button{


  font-family: 'Arimo', sans-serif;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
  background-color: #831200;
  color: white;
  border: 2px solid black;
  text-decoration: none;
  transition: all 0.5s ease-out;

}
.display-button:hover,
.display-button:focus{
  background-color:  #ffb863;
  color: black;
}

@media screen and (max-width: 700px) {
  .title-section-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .title-container-text{
    flex: 1;
    margin: 30px;
    color: black;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-family: 'Arimo', sans-serif;
    font-size: 23px;
    letter-spacing: 4px;
    word-spacing: 2px;
    line-height: 1.5;

  }
  .title-container-title{
    display: none;
  }
}

</style>
/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'
import {createPinia} from 'pinia';
import "@aws-amplify/ui-vue/styles.css"
import {useCollect} from "@/stores/collectionlist";
import axios from 'axios'


Amplify.configure(awsconfig)



createApp(App)
    .use(createPinia())
    .use(router, axios)
    .mount('#app')

const collectStore = useCollect()
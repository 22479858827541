<template>
  <div class="orders-container">
    <div v-if="auth.route === 'authenticated'"  class="control-container">
      <div>
        <button class="mx-3 button is-danger is-small" @click="deleteOrders">
          Delete Orders...
        </button>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="title my-2 mx-2">
            Current Orders
          </div>
          <div class="mx-3 order-table table-container">
            <table class="table" id="orders">
              <tr>
                <th>Date</th>
                <th>Order#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Shipped</th>
                <th>View</th>
              </tr>
              <tr v-for="order in orders" :key="order">
                <td>{{order.createdAt}}</td>
                <td>
                  <router-link :to="{name: 'customer_order', params:{ordernumber: order.id}}">
                    {{order.id}}
                  </router-link>
                </td>
                <td>{{order.name}}</td>
                <td>{{order.email}}</td>
                <td>{{order.phone}}</td>
                <td v-if="order.shipped === true">
                  Shipped.
                </td>
                <td v-else>
                  Not Shipped yet
                </td>
                <td>
                  <button @click="chooseOrder(order)" >View Full Order</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div v-if="current_order.id !==''" class="column is-4">
          <div class="title">
            Full Order.
          </div>
          <the-order :new_order="current_order"/>
        </div>
      </div>
    </div>
    <div v-else>
      <authenticator :services="{services}" :login-mechanisms="['username']" :hide-sign-up="true">
        <template  v-slot="{signOut}">
          <button @click="signOut">Sign Out</button>
        </template>
      </authenticator>
    </div>
  </div>
</template>

<script>
import {useAuthenticator} from "@aws-amplify/ui-vue";
import {Authenticator} from "@aws-amplify/ui-vue";
import {deleteOrder, getAllOrders} from "@/methods/sitemethods";
import TheOrder from "@/components/orders/TheOrder";
const auth = useAuthenticator()

export default {
  name: "OrdersView",
  components:{
    TheOrder,
    Authenticator
  },
  data(){
    return{
      auth,
      orders: [],
      current_order: {
        id: '',
        shipping: '',
        shipped: '',
        checkout_id: '',
        name: '',
        email: '',
        phone: '',
        city: '',
        country: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
        products: [],
        description: '',
        other: '',
      },
      local_tracking: '',
    }
  },
  mounted() {
    this.getOrders()
  },
  methods:{
    async sendTracking()
    {
      console.log("Send customer tracking info")
      confirm("Warning: This will send the customer an email with tracking number provided.")
    },
    chooseOrder(order_input)
    {
      console.log(order_input)
      this.current_order = order_input
    },
    async getOrders()
    {
      this.orders = []
      let temp = await getAllOrders()
      temp.forEach(order=>{
        order.order_products =[]
        if(order.products !== null)
        {
          order.products.forEach(product=>{
            order.order_products.push(JSON.parse(product).price.metadata)
          })
        }
        this.orders.push(order)
      })
      console.log(this.orders)

    },
    async deleteOrders()
    {
      for(let counter = 0; counter < this.orders.length; counter++)
      {
        let result = await deleteOrder(this.orders[counter])
        console.log(result)
      }
    }
  }
}
</script>

<style scoped>


</style>
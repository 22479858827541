<template>
  <div id="maincontainer" class="collection-container">
    <div class="outer-container" v-for="collection in collections" :key="collection">
        <the-collection :collection="collection"/>
    </div>
  </div>
</template>

<script>
import {getCollections} from "@/methods/formmethods";
import TheCollection from "@/components/TheCollection";

export default {
  name: "CollectionView",
  components: {TheCollection},
  data()
  {
    return{
      collections: [],
    }
  },
  mounted() {
    this.makeCollections()
  },
  methods: {
    async makeCollections()
    {
      this.collections = await getCollections()
      let temp = 0;
      this.collections.forEach((collection, index)=>{

        if(temp<=1)
          this.collections[index].right = true
        else if(temp >1)
          this.collections[index].right = false
        else if(temp === 3)
          temp = 0;
        temp+=1

      })
      let rows = Math.ceil(this.collections.length / 2)
      document.getElementById('maincontainer').setAttribute('style',`grid-template-rows: repeat(${rows},minmax(360px,auto));`)
    }
  },
}
</script>

<style scoped>
.collection-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #FFFDD1;


}
.collection-container >div{

  border: 1px black solid
}

.outer-container{
  display: flex;
}

@media screen and (max-width: 700px){

  .collection-container {
    display: grid;

    grid-template-columns: 1fr;
    background-color: #831200;
  }
}

</style>
<template>
  <div :class="{hide: $route.meta.navbar}" class="nav-box">
    <div>
      <router-link class="mobile-font general-title" style="color: white;  font-family: 'Orbitron', sans-serif; letter-spacing: 4px; " :to="{name: 'home'}">
        Terra Raffinata
      </router-link>
    </div>
    <div class="collection-row">
      <router-link class="general-title" style="font-size: 30px" :to="{name: 'shop_collections'}">
        Collections
      </router-link>
      <div class="collections-container">
        <router-link v-for="collection in collections" :key="collection" class="general-subtitle"
                     :to="{name: 'shop_collection_detail', params:{slug: collection.id}}">
          {{collection.title}}
        </router-link>
      </div>
    </div>
    <div class="products-row">
      <router-link class="general-title" style="color: white; font-size: 30px" :to="{name: 'all_products'}">
        Products
      </router-link>
    </div>
  </div>
</template>

<script>
import {getCollections} from '@/methods/formmethods'
export default {
  name: "TheNav",
  data()
  {
    return{
      collections: [],
    }
  },
  mounted() {
    this.makeCollections()
  },
  methods: {
    async makeCollections()
    {
      this.collections = await getCollections()
    }
  },

}
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Source+Serif+Pro:wght@300&display=swap');

.hide{
  display: none;
}

.nav-box{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}

.collections-container{
  display: grid;
  grid-template-columns: repeat(10,1fr);
  grid-gap: 1em;
}

.general-title{
  text-decoration: none;
  color: white;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 50px;
  font-weight: 400;
}
.general-subtitle{
  text-decoration: none;
  color: white;
  font-family: 'Arimo', sans-serif;
  font-size: 15px;
  font-weight: 100;
  letter-spacing: 3px;
  word-spacing: 2px;

}
@media screen and (max-width: 700px){
  .hide{
    display: grid;
  }
  .nav-box{
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

  }
  .collection-row{
    background-color: #ffb863;
    text-align: center;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding-top: 15px;
    padding-bottom: 15px;

  }
  .collections-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
  }
  .products-row{
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }


  .general-title{
    text-decoration: none;
    color: black;
    font-family: 'Lexend Giga', sans-serif;
    font-size: 35px;
    font-weight: 400;
  }
  .general-subtitle{
    text-decoration: none;
    color: black;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 3px;
    word-spacing: 2px;
  }

}

</style>
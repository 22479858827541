<template>
  <div class="zone-container">
    <div v-if="auth.route === 'authenticated'"  class="control-container">
      <div class="columns">
            <div class="column is-6">
              <div class="column is-6 mx-4">
                <p> Select a Collection to update..</p>
                <select class="select is-fullwidth" v-model="collection_input">
                  <option style="text-transform: capitalize" v-for="collection in collections" :value="collection" :key="collection">
                    {{collection.title}}
                  </option>
                </select>
              </div>
              <p class="title has-text-danger my-2">{{this.collection_status}}</p>
              <the-selection-collection v-on:newcollection="collectionFormReset" v-on:collection="collectionFormReset" :new_collection="collection_input"
                                        :collections="collections"/>
            </div>
            <div class="column is-6">
              <div class="columns  my-2">
                <div class="column is-6">
                  <button class="button is-fullwidth has-background-grey has-text-white" @click="createNewProduct(true)">
                    Create New Product
                  </button>
                </div>
                <div class="column is-6" @click="createNewProduct(false)">
                  <button class="button is-fullwidth has-background-black has-text-white">
                    Update Product
                  </button>
                </div>
              </div>
              <div class="column is-6">
                <p v-if="createNew" class="subtitle my-2">
                  Add New Product
                </p>
                <p v-if="!createNew" class="subtitle my-2">
                  Update Product
                </p>
                <p class="subtitle"> Select a Collection.</p>
                <select class="select is-fullwidth" v-model="selected_collection">
                  <option style="text-transform: capitalize" v-for="collection in collections" :value="collection" :key="collection">
                    {{collection.title}}
                  </option>
                </select>
                <div v-if="selected_collection.title !== '' && createNew !== true">
                  <p class="subtitle my-3">Select A Product</p>
                  <select class="select is-fullwidth" v-model="selected_product">
                    <option style="text-transform: capitalize" v-for="product in collection_products" :value="product" :key="product">
                      {{product.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <p class="title has-text-danger">
                  {{product_status}}
                </p>
              </div>
              <div v-if="selected_collection.title !==''">
                <div v-if="createNew">
                  <the-new-product :collection="selected_collection" />
                </div>
                <div v-if="createNew !== true && this.selected_product.id !== ''">
                  <the-update v-on:update="productFormReset" :collection="selected_collection" :input_product="selected_product" />
                </div>
              </div>
            </div>
      </div>
    </div>
    <div v-else>
      <authenticator :services="{services}" :login-mechanisms="['username']" :hide-sign-up="true">
        <template  v-slot="{signOut}">
          <button @click="signOut">Sign Out</button>
        </template>
      </authenticator>
    </div>
  </div>
</template>

<script>
import {
  addCollection,
  getCollections,
  getProducts,
  deleteProducts,
} from "@/methods/formmethods";
import {useAuthenticator} from "@aws-amplify/ui-vue";
import {Authenticator} from "@aws-amplify/ui-vue";
import TheSelectionCollection from "@/components/form/TheSelectionCollection";
import TheNewProduct from "@/components/form/TheNewProduct";
import TheUpdate from "@/components/form/TheUpdate";
const auth = useAuthenticator()


//import {useCollect} from "@/stores/collectionlist";



String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default {
  name: "AddProduct",
  components:{
    TheUpdate,
    TheNewProduct,
    TheSelectionCollection,
    Authenticator
  },
  data()
  {
    return{
      selected_collection: {title:''},
      collection_input: {title: ''},
      new_collection: '',
      collections: [],
      main_photo_pre: '',
      preview_photos_pre: [],

      main_photo_file: {name:''},
      preview_photo_files: [],

      main_file_name: '',
      preview_photo_names: [],

      selected_product: {
        id: '',
        name: '',
        description: '',
        available: false,
        price: 0,
        collectionProductId: ''},

      collection_products: [],

      form_bool_create: true,

      selected_previews: new Set(),
      product_photos: new Set(),

      createNew: true,
      product_status: '',
      collection_status: '',

      auth,



    }
  },
  watch:{
    collection_input()
    {

    },
    selected_collection()
    {
      console.log(this.selected_collection)
      if(this.selected_collection.title !=='')
      {
        this.getProducts()
        this.selected_product ={
        id: '',
            name: '',
            description: '',
            blurb: '',
            available: false,
            specifications: '',
            product_dimensions: '',
            shipping_cost: 0,
            price: 0,
            collectionProductId: ''}
       }
      this.product_status = ''
    },
    selected_product()
    {
      console.log(this.selected_product)
    }
  },
  mounted() {
    this.getCollections()
  },
  methods:{
    async productFormReset(message)
    {
      console.log("form Reset Called from a Product Child")
      await this.getCollections()
      await this.getProducts()
      this.selected_product = this.collection_products[0]
      this.product_status = message
    },
    async collectionFormReset(message)
    {
      console.log("Form reset Called From Collection")
      await this.getCollections()
      this.collection_input = this.collections[0]
      this.collection_status = message
    },
    createNewProduct(selection)
    {
      this.createNew = selection
    },
    async deleteSelectedProducts()
    {
      console.log(this.selected_previews)
      let temp_array = []
      this.selected_previews.forEach(product=>{
        temp_array.push(product)
      })
      for(let counter = 0; counter < temp_array.length; counter++)
      {
        await deleteProducts(temp_array[counter])
        await this.getProducts()
      }
    },
    formChange(change)
    {
      console.log(change)
      this.form_bool_create = change
      this.new_product()
    },
    selectPreview(event, product_id)
    {
      console.log(event)
      console.log(product_id)
      console.log(this.selected_previews)
      if(!this.selected_previews.has(product_id))
      {
        this.selected_previews.add(product_id)
        event.target.classList.add('selected_product')
      }
      else if(this.selected_previews.has(product_id))
      {
        this.selected_previews.delete(product_id)
        event.target.classList.remove('selected_product')
      }
    },
    async createCollection()
    {
      // Do Checks Report issues
      // Send to form methods
      if(this.new_collection.replaceAll(' ','') === '')
      {
        //Raise Form Error
        console.log("Empty Collection Name")
      }
      else
      {
        console.log("accepted Value: " + this.new_collection)
        let slug_id = this.new_collection.trimStart().trimEnd().replaceAll(' ', '-').toLowerCase()
        let collection_name = this.new_collection.trimStart().trimEnd()
        console.log("Slug ID: " + slug_id)
        console.log("Collection Name: " + collection_name)
        await addCollection(slug_id, collection_name)
        await this.getCollections()
        this.new_collection = ''

      }
    },
    async getCollections()
    {
      //this.$refs.collectionSelect.options.length
      console.log("Get Collections Called.")
      console.log(this.collections)
      this.collections = await getCollections()
    },
    async getProducts()
    {
      this.collection_products = await getProducts(this.selected_collection)
    },
  }

}
</script>

<style scoped>




</style>
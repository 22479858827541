<template>
  <div class="product-contain">
      <router-link class="product-photo-contain" :to="{name: 'shop_product', params:{collection: product.collectionProductId, product: product.id }}">
        <img class="product-photo" :src="photo">
      </router-link>
    <div class="product-info-contain">
      <div class="product-title has-text-centered">
        {{product.name}}
      </div>
      <div class="product-blurb" style="margin-top: 15px;">
        {{product.blurb}}
      </div>
      <div class="product-button">
        <router-link class="display-button" :to="{name: 'shop_product', params:{collection: product.collectionProductId, product: product.id }}">
          View Product
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {Storage} from "aws-amplify";

export default {
  name: "TheProduct",
  props:{
    product: {type: Object, required: true}
  },
  data(){
    return{
      photo: '',
    }
  },
  mounted() {
    this.getPhoto()
  },
  methods: {
    async getPhoto(){
      this.photo = await Storage.get(this.product.image_key)
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Source+Serif+Pro:wght@300&display=swap');

$orange-code: #e06f1f;
$yellow-code: #FFFDD1;

.product-contain{
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 300px minmax(150px, auto);
  background-color: $orange-code;
  color: white;
}
.product-photo-contain{
  grid-column: 1;
  display: flex;

}
.product-photo{
  object-fit: cover;
  flex: 1;
  aspect-ratio: 1/1;
}
.product-info-contain{
  grid-row: 2;
  display: flex;
  flex-direction: column;
}
.product-title{

  height: 60px;
  font-family: 'Lexend Giga', sans-serif;
  font-size: 24px;
  letter-spacing: 4px;
  font-weight: 500;
  color: black;
  padding: 10px 10px;

}
.product-blurb{
  text-align: center;
  padding: 10px;
  font-family: 'Arimo', sans-serif;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 3px;
  word-spacing: 2px;
  line-height: 1.5;
  height: 90px;
  color: black;
}
.product-button{
  align-self: center;
  display: flex;
}
.display-button{


  font-family: 'Arimo', sans-serif;
  padding-left: 110px;
  padding-right: 110px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
  background-color: #ffb863;
  color: black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 2px solid black;



  text-decoration: none;
  transition: all 0.5s ease-out;

}
.display-button:hover,
.display-button:focus{
  background-color: #831200;
  color: white;
}
@media screen and (max-width: 700px) {
  .product-title{

    height: 70px;
    font-family: 'Lexend Giga', sans-serif;
    font-size: 23px;
    letter-spacing: 4px;
    font-weight: 500;
    color: white;
    padding: 10px 10px;

  }
  .display-button{
    font-family: 'Arimo', sans-serif;
    padding-left: 110px;
    padding-right: 110px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 5px;
    word-spacing: 2px;
    line-height: 1.5;
    background-color: #ffb863;
    color: black;
    border-top: 2px solid black;
    text-align: center;



    text-decoration: none;
    transition: all 0.5s ease-out;

  }

  .product-blurb{
    text-align: center;
    padding: 10px;
    padding-top: 25px;
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 3px;
    word-spacing: 2px;
    line-height: 1.5;
    height: 100px;
    color: black;
  }



}

</style>
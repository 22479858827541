<template>
  <div class="checkout-container">
    <div v-if="product.available" class="price-text">
      {{(product.price /100).toLocaleString('en-US',{
      style:'currency',
      currency:'USD'})}}
    </div>

    <button v-if="product.available" id="submit-button" class="button is-large display-button" @click="gotoCheckout">
      Purchase Item
    </button>
    <button class="button is-large display-button" v-else>
      Not Available
    </button>
    <div v-if="product.available" class="stripe-disclaimer">
      Powered by <a href="https://stripe.com/">Stripe</a> Checkout
    </div>
  </div>
</template>

<script>

import {loadStripe} from '@stripe/stripe-js'
import axios from "axios";

String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default {
  name: "TheCheckout",
  props: {
    product: {type: Object, required: true}
  },
  data()
  {
    return{
      cancel_path: '',
      success_path: '',
      order_id: '',

    }
  },
  mounted() {
    this.getRoute()
  },
  methods: {
    getRoute()
    {
      this.cancel_path = window.location.href.toString()
      // Generate order Number
      let random_num = Math.floor(Math.random() *100000)
      let ordernumber = (random_num.toString()).hashCode().toString()
      this.success_path = window.location.origin.toString() + '/customer/orders/' + ordernumber
      this.order_id = ordernumber
    },
    async gotoCheckout()
    {
      const stripe = await loadStripe('pk_test_51L47fiIjuNDRy6m2mqI8EWM88HgLv9Hnke7db7UEw8KyAVXix5WCw757hJO6U4Hk7J0GhscBATtIi9DxUYGM5MuJ000cSatEQc')
      let product = this.product
      product.cancel_url = this.cancel_path
      product.success_url = this.success_path
      product.order_id = this.order_id
      let response = await axios.post("https://dgegk68pf8.execute-api.us-east-1.amazonaws.com/createSession",product)
      console.log(response)
      await stripe.redirectToCheckout({sessionId: response.data.id})
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@300&display=swap');
.checkout-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(50px, auto);
  grid-gap: 5px;
}
.price-text{
  grid-column: 1;
  grid-row: 1;
  background-color: #831200;

  text-align: center;
  color: white;
  font-family: 'Lexend Giga', sans-serif;
  font-weight: 300;
  font-size: 25px;
  padding-top: 8px;
  border: 1px black solid;

}
.disclaimer-text{
  grid-column: 1/2;
  grid-row: 2;
  font-family: 'Arimo', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #831200;
}
.stripe-disclaimer{
  grid-column: 2/3;
  grid-row: 2;
  font-family: 'Arimo', sans-serif;
  font-size: 16px;
  font-weight: 700;

}
.display-button{
  font-family: 'Lexend Giga', sans-serif;
  grid-column: 2;
  flex: 1;
  background-color: black;
  color: white;
  border: 1px solid;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-out;
}
.display-button:hover,
.display-button:focus{
  background-color: white;
  color: black;
  box-shadow: 10px 5px 25px black;
}

@media screen and (max-width: 700px){
  .checkout-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(50px, auto);
    grid-gap: 5px;
  }
  .display-button {
    font-family: 'Lexend Giga', sans-serif;
    grid-column: 1;
    grid-row: 2;
  }
  .disclaimer-text{
    grid-column: 1;
    grid-row: 4;
    font-family: 'Arimo', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #831200;
  }
  .stripe-disclaimer{
    grid-column: 1;
    grid-row: 3;
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-decoration: #831200 underline;
    text-align: center;
  }
}

</style>
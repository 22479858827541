/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      collection {
        items {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      collection {
        items {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      collection {
        items {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      title
      description
      blurb
      image_key
      shop {
        id
        name
        collection {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        items {
          id
          name
          description
          blurb
          price
          stripe_id
          available
          image_key
          specifications
          product_dimensions
          shipping_cost
          createdAt
          updatedAt
          collectionProductId
        }
        nextToken
      }
      createdAt
      updatedAt
      shopCollectionId
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      title
      description
      blurb
      image_key
      shop {
        id
        name
        collection {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        items {
          id
          name
          description
          blurb
          price
          stripe_id
          available
          image_key
          specifications
          product_dimensions
          shipping_cost
          createdAt
          updatedAt
          collectionProductId
        }
        nextToken
      }
      createdAt
      updatedAt
      shopCollectionId
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      title
      description
      blurb
      image_key
      shop {
        id
        name
        collection {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        items {
          id
          name
          description
          blurb
          price
          stripe_id
          available
          image_key
          specifications
          product_dimensions
          shipping_cost
          createdAt
          updatedAt
          collectionProductId
        }
        nextToken
      }
      createdAt
      updatedAt
      shopCollectionId
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      collection {
        id
        title
        description
        blurb
        image_key
        shop {
          id
          name
          createdAt
          updatedAt
        }
        product {
          nextToken
        }
        createdAt
        updatedAt
        shopCollectionId
      }
      name
      description
      blurb
      price
      stripe_id
      available
      image_key
      specifications
      product_dimensions
      shipping_cost
      preview {
        items {
          id
          image_keyy
          createdAt
          updatedAt
          productPreviewId
        }
        nextToken
      }
      createdAt
      updatedAt
      collectionProductId
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      collection {
        id
        title
        description
        blurb
        image_key
        shop {
          id
          name
          createdAt
          updatedAt
        }
        product {
          nextToken
        }
        createdAt
        updatedAt
        shopCollectionId
      }
      name
      description
      blurb
      price
      stripe_id
      available
      image_key
      specifications
      product_dimensions
      shipping_cost
      preview {
        items {
          id
          image_keyy
          createdAt
          updatedAt
          productPreviewId
        }
        nextToken
      }
      createdAt
      updatedAt
      collectionProductId
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      collection {
        id
        title
        description
        blurb
        image_key
        shop {
          id
          name
          createdAt
          updatedAt
        }
        product {
          nextToken
        }
        createdAt
        updatedAt
        shopCollectionId
      }
      name
      description
      blurb
      price
      stripe_id
      available
      image_key
      specifications
      product_dimensions
      shipping_cost
      preview {
        items {
          id
          image_keyy
          createdAt
          updatedAt
          productPreviewId
        }
        nextToken
      }
      createdAt
      updatedAt
      collectionProductId
    }
  }
`;
export const createPreview = /* GraphQL */ `
  mutation CreatePreview(
    $input: CreatePreviewInput!
    $condition: ModelPreviewConditionInput
  ) {
    createPreview(input: $input, condition: $condition) {
      id
      image_keyy
      product {
        id
        collection {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        name
        description
        blurb
        price
        stripe_id
        available
        image_key
        specifications
        product_dimensions
        shipping_cost
        preview {
          nextToken
        }
        createdAt
        updatedAt
        collectionProductId
      }
      createdAt
      updatedAt
      productPreviewId
    }
  }
`;
export const updatePreview = /* GraphQL */ `
  mutation UpdatePreview(
    $input: UpdatePreviewInput!
    $condition: ModelPreviewConditionInput
  ) {
    updatePreview(input: $input, condition: $condition) {
      id
      image_keyy
      product {
        id
        collection {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        name
        description
        blurb
        price
        stripe_id
        available
        image_key
        specifications
        product_dimensions
        shipping_cost
        preview {
          nextToken
        }
        createdAt
        updatedAt
        collectionProductId
      }
      createdAt
      updatedAt
      productPreviewId
    }
  }
`;
export const deletePreview = /* GraphQL */ `
  mutation DeletePreview(
    $input: DeletePreviewInput!
    $condition: ModelPreviewConditionInput
  ) {
    deletePreview(input: $input, condition: $condition) {
      id
      image_keyy
      product {
        id
        collection {
          id
          title
          description
          blurb
          image_key
          createdAt
          updatedAt
          shopCollectionId
        }
        name
        description
        blurb
        price
        stripe_id
        available
        image_key
        specifications
        product_dimensions
        shipping_cost
        preview {
          nextToken
        }
        createdAt
        updatedAt
        collectionProductId
      }
      createdAt
      updatedAt
      productPreviewId
    }
  }
`;
export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      shipping
      shipped
      checkout_id
      name
      email
      phone
      city
      country
      line1
      line2
      postal_code
      state
      products
      description
      other
      createdAt
      updatedAt
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      shipping
      shipped
      checkout_id
      name
      email
      phone
      city
      country
      line1
      line2
      postal_code
      state
      products
      description
      other
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      shipping
      shipped
      checkout_id
      name
      email
      phone
      city
      country
      line1
      line2
      postal_code
      state
      products
      description
      other
      createdAt
      updatedAt
    }
  }
`;

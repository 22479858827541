<template>
  <div class="collection-selection-container">
    <div class="selection-title">
      Collections
    </div>
    <div class="column-container">
      <router-link class="selection-container" :to="{name: 'shop_collection_detail', params:{slug: 'bowls'}}">
        <img class="selection-image" src="@/assets/photos/frontpage/imageFive.jpg" alt="whoops">
        <p class="selection-text"  style="background: black; padding: 5px;">
          Bowls
        </p>
      </router-link>
      <router-link  class="selection-container" style="background:linear-gradient(0.2turn,#ffff00,#efdd36, #ffffbf,#ffff80, #ffe288)"
                    :to="{name: 'shop_collection_detail', params:{slug: 'vases'}}" >
        <img class="selection-image" src="@/assets/photos/frontpage/imageTwo.jpg" alt="whoops">
        <p class="selection-text" style="background: black; padding: 5px;">
          Vases
        </p>
      </router-link>
      <router-link class="selection-container" :to="{name: 'shop_collection_detail', params:{slug: 'mugs'}}">
        <img class="selection-image" src="@/assets/photos/frontpage/imageThree.jpg" alt="whoops">
        <p class="selection-text"  style="background: black; padding: 5px;">
          Mugs
        </p>
      </router-link>

      <router-link :to="{name: 'all_products'}" class="selection-container" style="background:linear-gradient(0.8rad,#e06f1f,#9d5716,#cc7500, #ffa900,#ffb863)">
        <img class="selection-image" src="@/assets/photos/frontpage/imageOne.jpg" alt="whoops">
        <p class="selection-text"  style="background: black; padding: 10px;">
          All
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheCollectionsSection"
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Orbitron:wght@700&display=swap');

.collection-selection-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 150px auto;
  justify-items: center;
  padding-top: 30px;
  padding-bottom: 40px;
  border-bottom: black 2px solid;
  border-top: black 2px solid;
}
.selection-title{
  grid-column: 1;
  grid-row: 1;
  font-family: 'Lexend Giga', sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  color: black; font-size: 70px;

}
.column-container{
  padding: 10px;
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  grid-gap: 50px;


}
.selection-container{
  position: relative;
  display: flex;
  color: white;
  border-radius: 50%;
  background: linear-gradient(0.2rad,#b30000,  #ffbbb3,#ff8080,#7e371a);
}
.selection-image{
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1/1;
  border: 50px solid transparent;
}
.selection-text {
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 5px;
  word-spacing: 2px;
  line-height: 1.5;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 700px) {

  .collection-selection-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 40px;
    border-bottom: black 2px solid;
    border-top: black 2px solid;
  }
  .selection-title{
    grid-column: 1;
    grid-row: 1;
    font-family: 'Lexend Giga', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    color: black; font-size: 40px;

  }
}

</style>
<template>
  <div class="customer-order-container">
        <div class="order-view">
          <div style="background-color: #ffb863; padding-bottom: 10px;">
            <div class="title-text">
              Hi, <span style="text-transform: capitalize">{{order.name}}</span>.
            </div>
            <div class="title-text" style="font-size: 30px;">
              Your Order has been made.
            </div>
            <div class="subtitle-text">
              Your Order Number is: <span style="text-decoration: underline">{{order.id}}</span>
            </div>
            <br>
            <div v-if="order.shipped !== true" class="subtitle-text" style="font-size: 18px;">
              <div class="subtitle-text" style="font-size: 18px;">
                Your items will be shipped soon. <br>
                Items are typically shipped within 2-3 business days
              </div>
            </div>
            <div v-else>
              <div class="subtitle-text" style="font-family: 'Orbitron', sans-serif; font-weight: 1000;">
                Your order has been shipped! <br>
                Tracking Number: {{order.shipping}}
              </div>
            </div>

            <br>
            <div class="subtitle-text" >
              You will receive an email at: <span style=" text-decoration: underline; font-size: 25px;">{{order.email}}</span> with tracking info once the order has been shipped.
            </div>
          </div>
          <div style="background-color: #831200;">
            <div class="title-text" style="color:white; font-size: 30px; padding-bottom: 20px;">
              Products Ordered
            </div>
            <div class="subtitle-text" style="color: white;" v-for="product in products" :key="product">
              <img class="product-image" :src="product.image_url">
              <div class="subtitle-text" style="color:white;">
                {{product.name}} <br class="my-2">
                <span style="font-size: 15px;">{{product.blurb}}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="title-text" style=" font-size: 45px; padding-top: 20px;">
              Thank You For Your Business
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import {getOrder, getProductPage} from "@/methods/sitemethods";
import {Storage} from "aws-amplify";

export default {
  name: "CustomerOrderView",
  data()
  {
    return {
      order: {},
      valid: true,
      products: [],

    }
  },
  mounted() {
    this.getOrder()
  },
  methods:{
    async getOrder()
    {
      let order_id = this.$route.params.ordernumber
      console.log(order_id)
      this.order = await getOrder(order_id)
      console.log(this.order)
      let products = []
      this.order.products.forEach(product=>{
        product = JSON.parse(product)
        products.push(product.price.metadata.product_id)
      })
      for(let counter = 0; counter < products.length; counter++)
      {
        let full_product = await getProductPage(products[counter])
        let photo = await Storage.get(full_product.image_key)
        full_product.image_url = photo
        this.products.push(full_product)
      }


    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Lexend+Giga&family=Source+Serif+Pro:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Source+Serif+Pro:wght@300&display=swap');
.customer-order-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

}
.order-view{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.product-image{
  flex: 1;
  max-width: 400px;
  border: black 2px solid;

}
.title-text{
  font-family: 'Orbitron', sans-serif;
  font-size: 70px;
  letter-spacing: 5px;
  font-weight: 500;
  color: black;
}
.subtitle-text{
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 4px;
  word-spacing: 2px;
  line-height: 2;
}

</style>